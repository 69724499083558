



























import { Vue, Component, Ref } from 'vue-property-decorator'
import { treeToFlatArray } from 'operation-tree-node'
import { variables, rgba } from '@/libs/theme'

@Component
export default class Home extends Vue {
  @Ref() map: HTMLDivElement

  statistic = { house: 0, person: 0, device: 0 }
  loading = false
  geoRegex = /^geo:(-?(\d{1,2}(\.\d+)?|1[0-7]\d(\.\d+)?|180)),(-?(\d(\.\d+)|[1-8]\d(\.\d+)?|90))$/

  get config() {
    return this.$store.state.config
  }

  get root() {
    const { areas } = this.$store.state.areas
    return areas.length > 0 ? areas[0] : {}
  }

  get areas() {
    return this.$store.state.areas.areas
  }

  get name() {
    return this.root.name ?? ''
  }

  async mounted() {
    if (this.areas.length === 0) {
      await this.$store.dispatch('areas/fetchAreas')
    }

    this.registerMaps().then(() => this.renderMap())
    this.fetchStatistic(this.root.id).then(statistic => (this.statistic = statistic))
  }

  registerMaps() {
    return Promise.all([
      this.$api.static.getGeoJson(110000).then(({ data }) => echarts.registerMap('Beijing', data)),
      this.$api.static.getGeoJson(100020).then(({ data }) => echarts.registerMap('Chaoyang', data))
    ])
  }

  async renderMap() {
    const chart = echarts.init(this.map, null, { renderer: 'svg' })

    addEventListener('resize', chart.resize)

    chart.setOption({
      geo: [
        {
          show: true,
          map: 'Beijing',
          center: [116.5, 39.99],
          zoom: 5.4,
          itemStyle: {
            areaColor: rgba(variables.backgroundColorBaseDarker, 0.8),
            borderColor: variables.backgroundColorBaseDark,
            borderWidth: 1.6
          },
          silent: true
        },
        {
          show: true,
          map: 'Chaoyang',
          center: [116.4936, 39.9889],
          zoom: 1.005,
          itemStyle: {
            areaColor: rgba(variables.colorPrimary, 0.6),
            borderColor: 'rgba(255, 255, 255, 0.8)',
            borderWidth: 1.6
          },
          silent: true
        }
      ],

      tooltip: {
        triggerOn: 'click'
      },

      series: [
        {
          name: 'beijing',
          type: 'map',
          mapType: 'epidemic-map',
          geoIndex: 0
        },
        {
          name: 'chaoyang',
          type: 'map',
          mapType: 'epidemic-map',
          geoIndex: 1
        },
        {
          name: 'sans',
          type: 'scatter',
          coordinateSystem: 'geo',
          label: {
            show: true,
            color: variables.textColorRegular,
            position: 'top',
            formatter: (params: AnyObj) => params.data.name
          },
          itemStyle: {
            opacity: 1
          },
          tooltip: {
            show: true,
            trigger: 'item',
            borderWidth: 0,
            position: (
              point: [number, number],
              params: AnyObj,
              dom: HTMLElement,
              rect: { x: number; y: number; width: number; height: number },
              size: { viewSize: [number, number]; contentSize: [number, number] }
            ) => {
              return [
                rect.x - size.contentSize[0] / 2 + rect.width / 2,
                rect.y - size.contentSize[1] - 30
              ]
            },
            formatter: (params: AnyObj, ticket: string, callback: Function) => {
              const { id, name } = params.data

              this.fetchStatistic(id).then(statistic => {
                const { house, person, device } = statistic

                callback(
                  ticket,
                  `<div class="area-tooltip">
                    <h4 class="title">${name}</h4>

                    <div class="statistic">
                      <div class="statistic-item">
                        <div class="label">实有人口</div>
                        <div class="number">${person}</div>
                      </div>
                      <div class="statistic-item">
                        <div class="label">实有房屋</div>
                        <div class="number">${house}</div>
                      </div>
                      <div class="statistic-item">
                        <div class="label">现有设备</div>
                        <div class="number">${device}</div>
                      </div>
                    </div>
                  </div>`
                )
              })

              return '加载中...'
            },
            extraCssText: `background: ${variables.backgroundColorBase}; color: ${variables.textColorRegular};`
          },
          symbol: 'image://' + require('@/assets/images/dashboard/location-area.svg'),
          symbolSize: [32, 32],
          symbolOffset: [0, '-50%'],
          geoIndex: 0,
          data: this.getAreas()
        }
      ]
    })
  }

  getAreas() {
    const areas = treeToFlatArray<AnyObj, AnyObj>(this.areas, undefined, {
      children: 'children'
    }).filter(({ level, mapLabel }: any) => level === 1 && this.geoRegex.test(mapLabel))

    return areas.map(({ id, name, mapLabel }) => {
      const location = (mapLabel as string)
        .replace('geo:', '')
        .split(',')
        .map(num => parseFloat(num))

      return { id, name, value: location }
    })
  }

  async fetchStatistic(areaId: string) {
    const statistic = { house: 0, person: 0, device: 0 }

    const fetchHouses = async () => {
      const { data } = await this.$api.europa.getHouseStatistic({ areaIds: [areaId] })

      if (data.code === 0) {
        const { count, occupy } = data.data

        statistic.house = count ?? 0
        statistic.person = occupy ?? 0
      }
    }

    const fetchEquipments = async () => {
      const { data } = await this.$api.base.getDevicesSearch({
        areaIds: [areaId],
        cascade: true,
        limit: 1
      })

      if (data.code === 0) {
        statistic.device = data.count ?? 0
      }
    }

    await Promise.all([fetchHouses(), fetchEquipments()])

    return statistic
  }
}
